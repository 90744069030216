import { GetStaticPropsResult } from 'next';
import { ReactElement } from 'react';

import CdsLayout from '@components/Layout/CdsLayout';
import Layout from '@components/Layout/Layout';
import { Metadata } from '@utils/pageUtils';
import { EnvironmentProps, getEnvProps } from '@utils/env';

export type PukPortalIndexProps = {
  title: string;
  metadata: Metadata;
  mostSearchedTerms: string[];
};

const PukPortalIndexPage = () => {
  return <></>;
};

PukPortalIndexPage.getLayout = function getLayout(
  page: ReactElement,
  props: PukPortalIndexProps & EnvironmentProps,
) {
  return props.metadata.isGpp ? (
    <Layout
      query=""
      isSearchPage={true}
      title={props.metadata.isGppDE ? 'Suche' : 'Search'}
      slug={'/search'}
      metadata={props.metadata}
      mostSearchedTerms={props.mostSearchedTerms}
      features={props.features}
    >
      {page}
    </Layout>
  ) : (
    <CdsLayout
      query=""
      isSearchPage={true}
      title={'Suche'}
      slug={'/search'}
      metadata={props.metadata}
      mostSearchedTerms={props.mostSearchedTerms}
      features={props.features}
    >
      {page}
    </CdsLayout>
  );
};

export async function getStaticProps(): Promise<
  GetStaticPropsResult<PukPortalIndexProps & EnvironmentProps>
> {
  const { DataFetcher } = await import('@utils/DataFetcher');
  const dataFetcher = new DataFetcher();
  const dataProps = dataFetcher.getIndexProps();
  if ('props' in dataProps) {
    return {
      ...dataProps,
      props: {
        ...dataProps.props,
        ...getEnvProps(),
      },
    };
  }

  return dataProps;
}

export default PukPortalIndexPage;
