import { PropsWithChildren, useCallback, useRef, useState } from 'react';
import { useEffect } from 'react';
import {
  globalStyles,
  interaction___search,
  location___globe,
} from '@neui/styleguide-commerzbank';
import { styled } from '@neui/core';

import { Metadata, isGpp, isGppDE } from '@utils/pageUtils';
import { createPortalEntity, useTracker } from '@utils/snowplowTracking';
import { SeoData } from '@components/SEO/SeoData';
import { MobileChecker } from '@utils/MobileChecker';
import { Header } from '@components/neui-components/atoms/Header';
import {
  $t,
  currentLanguage,
  languageDomains,
  LanguageType,
} from '@utils/i18n';
import { Footer } from '@components/neui-components/atoms/Footer';
import { baseTheme } from 'styling/stitches.config';
import { MostReadArticleType } from 'pages/index';
import { useMakeLink } from '@components/Link';
import { EnvironmentProps } from '@utils/env';
import { CdsSearchAndResults } from '@components/Search/CdsSearchAndResults';
import { IsPukProvider } from '@utils/isPukBoolean';

import { NavLink, NavItemProps } from '../NavItems/CdsNavItems';
import { OnlineBankingIcon } from './CdsLayout';

type LayoutProps = {
  title: string;
  slug: string;
  query?: string;
  isIndexPage?: boolean;
  isSearchPage: boolean;
  metadata: Metadata;
  mostSearchedTerms?: string[];
  mostReadArticles?: MostReadArticleType[];
  excerpt?: string;
  features: EnvironmentProps['features'];
};

export default function Layout({
  children,
  isIndexPage = false,
  title,
  slug,
  isSearchPage,
  metadata,
  mostSearchedTerms,
  mostReadArticles,
  excerpt,
  features,
}: PropsWithChildren<LayoutProps>) {
  globalStyles();
  globalStyles();

  const { trackPageView, enableLinkClickTracking } = useTracker(Layout.name);

  const openSearch = useRef(() => {});
  const closeSearch = useRef(() => {});

  const [hasSearchResults, setHasSearchResults] = useState(false);

  const makeLink = useMakeLink();

  useEffect(() => {
    const portalContext = createPortalEntity($t('HOME_GPP'), title);
    enableLinkClickTracking?.({
      pseudoClicks: true,
      options: {
        denylist: isIndexPage ? ['c-fUCAFi'] : [],
      },
      context: [portalContext],
    });

    // hack: since snowplow tracking sometimes seems to pick up the old title, postpone tracking the page view into the next animation frame
    window.requestAnimationFrame(() => {
      trackPageView?.({ context: [portalContext] });
    });
  }, [enableLinkClickTracking, trackPageView, title, isIndexPage]);

  useEffect(() => {
    if (isSearchPage) {
      openSearch.current();
    }
  }, []);

  const { searchUrl, constants } = metadata;

  const foreignLanguage = Object.keys(languageDomains).filter(
    (lang) => lang !== currentLanguage,
  )[0] as LanguageType;

  function onLanguageLinkClick() {
    const newLanguage = currentLanguage === 'en' ? 'de' : 'en';
    location.href = languageDomains[newLanguage];
  }

  const searchIconProps = {
    label: $t('SEARCH'),
    icon: interaction___search,
    onClick: () => openSearch.current(),
    shouldShow: true,
  };

  const onlineBankingIconProps = {
    label: $t('ONLINE_BANKING'),
    icon: <OnlineBankingIcon />,
    href: $t('ONLINE_BANKING_URL'),
    onClick: () => {},
    ['data-cy']: 'search-icon',
    shouldShow: true,
  };

  const languageLinkProps = {
    label: foreignLanguage.toUpperCase(),
    icon: location___globe,
    onClick: onLanguageLinkClick,
    shouldShow: isGpp,
  };

  const isSearchVisible = isIndexPage || isSearchPage;

  const metaRobotsTag =
    (isGpp || isGppDE) && !isSearchPage
      ? 'noindex, follow'
      : 'noindex, nofollow';

  const googleSiteVerificationTag =
    process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION_TAG;

  const logoSrc = makeLink({
    href: '/commerzbank-logo.png',
    absoluteUrl: true,
    alwaysPrependBasePath: true,
  });

  const mobileChecker = new MobileChecker();
  const mobileOs = mobileChecker.deviceType;
  // const seoTitle = isIndexPage ? title : 'Commerzbank Kundenservice';

  const is404 = slug.includes('404');
  const seoTitle = isIndexPage
    ? is404
      ? title
      : $t('SEO_TITLE_APPENDIX_GPP')
    : title + ' - ' + $t('SEO_TITLE_APPENDIX_GPP');

  const navLinks: NavItemProps[] = [
    searchIconProps,
    languageLinkProps,
    onlineBankingIconProps,
  ];

  const inputRef = useRef<HTMLInputElement>(null);

  const absoluteUrl = makeLink({ href: slug, absoluteUrl: true }) ?? '';

  const setOpenSearch = useCallback(
    (f: () => void) => (openSearch.current = f),
    [],
  );

  const setCloseSearch = useCallback(
    (f: () => void) => (closeSearch.current = f),
    [],
  );

  return (
    <IsPukProvider value={false}>
      <MinHeightLayout className={baseTheme}>
        <SeoData
          title={seoTitle}
          description={excerpt ?? undefined}
          absoluteUrl={absoluteUrl}
          slug={slug}
          metaRobotsTag={metaRobotsTag}
          googleSiteVerificationTag={googleSiteVerificationTag}
          documentLanguage={currentLanguage}
          isSearchVisible={isSearchVisible}
          isIndexPage={isIndexPage || isSearchPage}
          imageSrc={logoSrc}
        />

        <Header
          title={$t('HEADER_TITLE_GPP')}
          navItems={
            <nav>
              <NavWrapper>
                {navLinks.map((navLink, idx) => (
                  <IconLinkWrapper key={idx}>
                    <NavLink
                      isLanguageSwitch={idx === navLinks.length - 2}
                      isSvg={idx === navLinks.length - 1}
                      {...navLink}
                    />
                  </IconLinkWrapper>
                ))}
              </NavWrapper>
            </nav>
          }
        />
        <CdsSearchAndResults
          isSearchPage={isSearchPage}
          searchApiUrl={searchUrl}
          mostSearchedTerms={mostSearchedTerms}
          inputRef={inputRef}
          mobileOs={mobileOs}
          mostReadArticles={mostReadArticles ?? []}
          setOpenSearch={setOpenSearch}
          setCloseSearch={setCloseSearch}
          onHasResults={setHasSearchResults}
          nachKonPortalUrl={''}
          isDE={isGpp}
        />
        <GrowingMain>{!hasSearchResults && children}</GrowingMain>
        <Footer constants={constants} features={features} />
      </MinHeightLayout>
    </IsPukProvider>
  );
}

const MinHeightLayout = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
});

const GrowingMain = styled('main', {
  flexGrow: 1,
  overflow: 'hidden',
});

const NavWrapper = styled('ul', {
  display: 'flex',
  flexDirection: 'row',
  gap: 24,
  '@md': {
    gap: 32,
  },
});

const IconLinkWrapper = styled('li', {
  listStyle: 'none',
  display: 'flex',
  justifyContent: 'center',
  maxWidth: 24,
  maxHeight: 24,
  '@md': {
    maxWidth: 'none',
    maxHeight: 'none',
  },
});
